import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Home Works" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../105-db2-notes"
        }}>{`Db2 Notes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../42-working-the-djinn"
        }}>{`Working the djinn`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../40-wealth-education"
        }}>{`Quest for Wealth - Value Investor Education`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../36-side-projects"
        }}>{`Side projects`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../37-tedx"
        }}>{`Notes from Tedx`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../53-sre-references"
        }}>{`Site Reliability Engineering`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../112-stock-market-terms"
        }}>{`Stock market terms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../74-shortcuts-and-commands"
        }}>{`Shortcuts and commands`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      