import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [3, 4, 5],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hello, I am Sushanth.
    </Text>
    <p>{`Welcome to my site. `}</p>
    <p>{`I am a mainframe Db2 DBA. Yep, mainframe is still there and as usual its highly scalable, highly available and highly secured. `}</p>
    <p>{`If you don't know what mainframe is, you should see `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=ar0xLps7WSY"
      }}>{`this`}</a>{`, this is how our work environment looks like. `}</p>
    <p><a parentName="p" {...{
        "href": "../hello-world"
      }}>{`This`}</a>{` site is my sandbox, probably will contain collection of my unorganized programming notes, things i learned doing some home projects, fav. music, self development, notes taken while watching youtube videos, etc., more or less.  `}</p>
    <p><strong parentName="p">{`Thanks`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      